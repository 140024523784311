import { atom } from 'recoil'

export const sessionStatusState = atom({
  key: 'sessionStatusState',
  default: {
    walletConnected: false,
    googleConnected: false,
    googleTokenValidityStatus: undefined,
  },
})
